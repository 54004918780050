// import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { SILog } from '@shein-aidc/monitor-core'
import { getCookie } from '@shein/common-function'
import { definedErrorJs } from './error-js'

function getMemberId() {
  if(typeof window === 'undefined') {
    return ''
  }

  // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
  let memberId = getCookie('memberId')

  try {
    if(memberId) {
      return memberId
    }

    const authJsonStr = localStorage.getItem('auth_member') || 'null'
    const authJson = JSON.parse(authJsonStr) || { value: {} }
    return authJson.value.member_id || ''
    // eslint-disable-next-line no-empty
  } catch (e) {
    return ''
  }
}

if (typeof window !== 'undefined') {
  const { PUBLIC_CDN, SERVER_TYPE, NODE_SERVER_ENV, IS_RW, SiteUID, appLanguage, currency } = gbCommonInfo
  __webpack_public_path__ = `${PUBLIC_CDN}/she_dist/assets/`

  // 1. 初始化日志信息，会同步执行 SLog.init
  SILog.init({
    server_type: SERVER_TYPE === 'usa' ? 'us' : SERVER_TYPE, // 数据中心
    brand: IS_RW ? 'romwe' : 'shein',
    device_type: 'pc',
    language: appLanguage,
    currency: currency,
    env: NODE_SERVER_ENV === 'production' ? 'production' : 'debug', // 环境
    site_uid: SiteUID,
  })

  // 2. 日志公共字段注册 —— 看场景需要
  SILog.registerField({
    member_id: getMemberId,
    // device_id: () => {
    //   return UserInfoManager.get({ key: 'oest' })
    // },
    params() {
      return {
        server_from: 'aidc-web-pc', // 日志是多个终端（app，web-pc,pwa,h5, ug）使用，增加字段筛选方便查看
        page_name: window.SaPageInfo?.page_name || 'other',
      }
    }
  })

  // 3. 日志消费配置
  const config = {
    // 缓存配置
    cache: {
      loop_time: 5000, // 5s 执行一次缓存检查上报
    },
    record_when_page_hidden: true, // 页面后台不可见依然可以保持记录
  }
  if (NODE_SERVER_ENV === 'localhost') {
    // 本地环境
    const fetchlog = sessionStorage.getItem('skynet_fetch') == '1' // 本地环境是否上报
    const locallog = sessionStorage.getItem('skynet_log') == '1' // 不上报本地环境是否打印到控制台
    if (!fetchlog) {
      config.http = {
        generator() {
          return (request) => {
            // eslint-disable-next-line no-console
            locallog && console.log('@@@@@@@@@@@@@@@@@', request.url.replace(/.*\/app-track\//, ''), request?.data?.data, '@@@@@@@@@@@@@@@@@')
          }
        },
      }
    }
  }
  SILog.config(config)

  // 兼容定义原来的错误上报
  definedErrorJs(SILog)

  if (!(gbCommonInfo.ENABLE_MONITOR_UNION || (gbCommonInfo.ENABLE_MONITOR_UNION_PAGE !== '' && gbCommonInfo.templateType === gbCommonInfo.ENABLE_MONITOR_UNION_PAGE))) {
    // 客户端执行监控
    import(/* webpackChunkName: "client-monitor" */ './client-monitor')
  }
}

export default SILog
export const SILogger = SILog.SILogger
export const SIMetric = SILog.SIMetric
