/**
 * 职责： 用于收集埋点数据
 */

import { collectDataAssign } from './utils' 
class CollectData {
  constructor({ saNames, singleKey, saNameDefaultLimit, handleAction, keepAlive }) {
    this.saNameMap = this.initSaNameMap(saNames)
    this.saNameDefaultLimit = saNameDefaultLimit || 30 // 默认限制30条
    this.isStart = false // 开始记录状态
    this.sessionId = null
    this.singleKey = singleKey || false
    this.onWatch = () => {}
    this.data = {}
    this.onceData = {}
    this.handleAction = handleAction || ((v) => v)
    if (keepAlive) {
      // 此时不需要进行路由控制，直接开始
      this.startCollect() // 开始记录, 传递true强制开始
    }
  }

  initSaNameMap(saNames = []) {
    if (!Array.isArray(saNames)) {
      return { list: [], moreNames: [] }
    }
    const moreNames = []
    const list = []
    saNames.forEach(item => {
      if (typeof item === 'string') {
        item = { saName: item, limit: this.saNameDefaultLimit }
      }
      item.saName.includes(',') ? moreNames.push(item) : list.push(item)
    })
    return { list, moreNames }
  }

  getData() {
    const onceCurrentData = this.onceData || {}
    if (Object.keys(onceCurrentData).length > 0) {
      const data = collectDataAssign(this.data, onceCurrentData)
      this.onceData = {}
      return this.handleExpireData(data)
    } else {
      return this.handleExpireData(this.data)
    }
  }

  handleExpireData(data) {
    return Object.keys(data).reduce((acc, sessionId) => {
      const expireTimeList = data[sessionId]?._expireTimeList
      if (!expireTimeList) {
        acc[sessionId] = data[sessionId]
        return acc
      }
      acc[sessionId] = Object.keys(data[sessionId]).reduce((acc, activity_name) => {
        const _dataItem = data[sessionId][activity_name] || []
        const _expireTimeList = expireTimeList[activity_name] || []
        if (!_expireTimeList.length) {
          acc[activity_name] = _dataItem
          return acc
        }
        const result = _dataItem.filter((item, index) => {
          return !_expireTimeList[index] || _expireTimeList[index] > Date.now()
        })
        acc[activity_name] = result
        return acc
      }, {})
      return acc
    }, {})
  }

  // 初始化数据时，设置数据
  setInitData(value = {}) {
    this.data = Object.assign({}, value)
    let keys = Object.keys(this.data)
    if (keys.length === 0) return
    if (!this.singleKey) {
      keys = keys.sort((a, b) => b - a) // 降序
    }
    this.sessionId = keys[0]
    this.isStart = true
  }

  registerHandler({ watch }) {
    this.onWatch = watch
  }

  /**
   * @description: 对外暴露的开始方法，每次路由跳转时命中目标路由时调用
   * */
  startCollect({ to, from } = {}) {
    this.isStart = true
    if (!this.sessionId) {
      this.sessionId = Date.now()
    } else if (!this.singleKey) {
      this.handleMoreSessionIdData({ to, from })
    }

    if (!this.data[this.sessionId]) {
      this.data[this.sessionId] = to ? {
        _routeData: {
          to: {
            query: to.query,
            params: to.params,
            path: to.path
          },
          from: {
            query: from.query,
            params: from.params,
            path: to.path
          }
        },
      } : {}
    }
  }

  /* 处理多sessionId数据生成 */
  handleMoreSessionIdData({ to, from }) {
    // 判断当前路由是否有在之前记录中：
    // 1. 如果没有则重新生成
    // 2.如果有则用之前的sessionId，
    //   （1）找到之前的sessionId,并判断是否是在最后一项的生成顺序中，如果在 那就直接使用。
    //    如果不在最后一项，判断是返回后的  还是新创建的页面。
    const sessionIds = Object.keys(this.data)
    const isGenarate = sessionIds.some(
      sid => this.data[sid]?._routeData?.to?.path === to.path
    )
    // 如果没有找到则重新生成
    if (!isGenarate) {
      this.sessionId = Date.now()
      return
    }

    //  判断是否是在最后一项的生成顺序中
    const lowerSortSessionIds = sessionIds.sort((a, b) => b - a)
    const startSessionId = lowerSortSessionIds[0]
    if (this.data[startSessionId]?._routeData?.to?.path === to.path) {
      this.sessionId = startSessionId
      // this.handleResetSessionIdData()
      return
    }

    // 此时创建的是在中间的id， 判断是返回后的  还是新创建的页面
    const index = lowerSortSessionIds.findIndex((sid) => this.data[sid]?._routeData?.to?.path === to.path) // 会存在重复记录目标，取最近一次
    const backSessionId = lowerSortSessionIds[index]
    const currentData = this.data[backSessionId]
    if (currentData._routeData.to.path === to.path && currentData._routeData.from.path === from.path) {
      // 此时命中 记录一模一样的，说明就是新创建的页面
      this.sessionId = Date.now()
    } else {
      // 页面进行返回后时触发
      this.sessionId = backSessionId
      const currentCollectData = lowerSortSessionIds.slice(0, index + 1).reduce((obj, k) => {
        obj[k] = this.data[k]
        return obj
      }, {})
      this.data = currentCollectData
      // this.handleResetSessionIdData()
    }
  }

  // 重置当前session数据
  // handleResetSessionIdData() {
  //   const currentSessionIdData = this.data[this.sessionId] || {}
  //   this.data[this.sessionId] = {
  //     _routeData: currentSessionIdData._routeData,
  //   }
  // }

  /**
   * @description: 对外暴露的结束方法
   * */
  endCollect() {
    this.isStart = false
    this.data = {}
    this.onWatch(this.data)
  }

  // 获取当前埋点的单个数据和多个数据
  getSaName(saData) {
    if (!this.isStart || !(this.saNameMap?.list?.length || this.saNameMap?.moreNames?.length || !saData?.activity_name)) {
      return {}
    }
    const saName = this.saNameMap.list.find(item => item.saName === saData.activity_name) // 单个埋点
    const moreSaName = this.saNameMap.moreNames.find(item => item?.saName?.split(',')?.includes(saData.activity_name)) // 多个埋点
    return { saName, moreSaName }
  }

  // 处理超过限制的数据
  handleSaNameLimitData({ saName, moreSaName }) {
    const currentData = this.data[this.sessionId]
    const setSaNameLimitData = ({ item }) => {
      if (!item) return
      const limit = item.limit || this.saNameDefaultLimit
      const currentActivityData = currentData[item.saName] || []
      if (currentActivityData.length >= limit) {
        currentActivityData.shift() // 超过限制，删除第一条
      }
    }
    ;[moreSaName, saName].forEach(item => setSaNameLimitData({ item }))
  }

  /**
   * @description: 埋点触发
   * */
  trigger(value) {
    if (value?.activity_param?._userTrackDisable) {
      // 用户自定义埋点禁用
      return 
    }
    this.handleSetData(value, this.data)
  }

  triggerCustomData(value) {
    if (!value || typeof value !== 'object') {
      console.warn('triggerCustomData value must be an object')
      return false
    }
    const objData = this.data[this.sessionId]
    if (!objData) return false
    objData.customData = Object.assign({}, objData.customData || {}, value)
    this.onWatch(this.data, value)
    return true
  }

  onceSet(value = {}, { to, from }) {
    if (!Object.keys(this.data || {}).length && to && from) {
      this.startCollect({ to, from })
    }
    if (!this.onceData[this.sessionId]) {
      this.onceData[this.sessionId] = {}
    }
    const id = Math.random().toString(36).slice(6) // 生成唯一id
    value.id = id
    this.handleSetData(value, this.onceData)
    return id
  }

  handleSetData(value, result) {
    const objData = result[this.sessionId]
    if (!objData) return // 没有session数据
    const { saName, moreSaName } = this.getSaName(value)
    if (!(saName || moreSaName)) return false // 不在埋点列表中
    this.handleSaNameLimitData({ saName, moreSaName })

    const { activity_name, activity_param, page_name, page_param, timestamp, end_time } = value
    const setSaNameData = ({  saName: key, limitTime } = {}) => {
      if (!key) return
      if (!objData[key]) {
        objData[key] = []
      }
      const param = {
        activity_name,
        activity_param: {
          ...(activity_param || {}),
          _actionId: activity_param?._actionId,
        },
        page_param: Object.assign({}, page_param),
        page_name,
        timestamp,
        end_time
      }
      if (value.id) {
        param.id = value.id
      }
      const _param = this.handleAction ? this.handleAction(param, this.data, { result }) : param
      const nextIndex = _param && objData[key].push(_param)
      if (limitTime && nextIndex) {
        // 过期时间 = 当前时间 + 限制时间(ms)
        const expireTime = Date.now() + limitTime
        if (!result[this.sessionId]._expireTimeList) {
          result[this.sessionId]._expireTimeList = {}
        }
        if (!result[this.sessionId]._expireTimeList[key]) {
          result[this.sessionId]._expireTimeList[key] = []
        }
        result[this.sessionId]._expireTimeList[key][nextIndex - 1] = expireTime
      }

    }
    ;[moreSaName, saName].forEach(item => setSaNameData(item))
    this.onWatch(result, value)
  }
}

export { CollectData }
