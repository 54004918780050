import UserKey from '../UserKey'
import { OestClientHandle } from '@shein-aidc/basis-oesthandle'

class Oest extends UserKey {
  static canHandle(key) {
    return key === 'oest'
  }

  get() {
    return OestClientHandle.get()
  }
  set(){
  }
}

export default Oest
