const _getLastItemByList = (list = []) => {
  return list?.length ? list[list.length - 1] : undefined
}

const _getSessionItemByList = (list = [], part = ',') => {
  return list?.length ? list.join(part) : undefined
}

const listPaths = [
  /^\/daily-new\.html$/,
  /^\/coupon-product\.html$/,
  /^\/sellingpoint-(\d+)-(\d+)-.+\.html$/,
  /^\/.+-c-(\d+)\.html$/,
  /^\/((\w+)\/)?.+-sc-([A-Za-z0-9]+)\.html$/,
  /^\/pdsearch\/([^\/]+)\/?$/,
  /^\/([a-z]+-picks|romwe-[a-z]+|promo-discount)(-list-([a-zA-Z0-9]+))?\.html$/,
]
const listFullPattern = listPaths.map(pat => pat.source).join('|')

const getSearchTop20Goods = (searchGoods) => {
  if (!searchGoods?.length) return []

  let searchTop20Goods = []
  let searchGoodsCount = 0
  const sortSearchGoods = searchGoods.sort((a, b) => b.timestamp - a.timestamp) // 按时间戳倒序

  for (let i = 0; i < sortSearchGoods.length; i++) {
    const { goodsIds, timestamp, session_id } = sortSearchGoods[i] || {}

    const curSessionLeftSearchGoods = goodsIds.slice(0, 20 - searchGoodsCount)
    
    const curSessionGoodIdsStr = _getSessionItemByList(curSessionLeftSearchGoods)
    
    searchTop20Goods.push(_getSessionItemByList([session_id, timestamp, curSessionGoodIdsStr], '`'))

    searchGoodsCount += curSessionLeftSearchGoods.length
    if (searchGoodsCount === 20) break
  }

  return searchTop20Goods
}

const config = {
  featureKey: 'detailsRecommend',  // 特征key
  saNames: [ // 所经过的埋点name
    'click_attribute', 
    'click_sort', 
    'click_category', 
    'click_goods_list_label', 
    'expose_goods_list',
    'page_view',
  ],
  targetPaths: [listFullPattern], // 目标页面
  clearDataPaths: [],
  handleAction(data) { // 每次收集到数据触发, data为每个埋点数据
    if (data.activity_name === 'page_view') {
      if (data.page_name !== 'page_search' || !data.page_param?.result_content) return null 
      return {
        result_content: data.page_param?.result_content // 只储存搜索页的result_content字段
      }
    }

    return data
  },
  onTrigger({ collectData, routerAction, getUserActionTracker }) {

    console.warn('detailsRecommend before', collectData, routerAction)
    const rt_ft_list_filter = [] //  筛选条件
    const rt_ft_list_price = [] //  价格区间
    const rt_f_list_sort = [] // 列表排序
    // const rt_f_list_top_nav = [] // 图文导航信息
    const rt_f_search_identifier = [] // 搜索页搜索词信息
    const rt_f_list_top_goods = [] // 列表首个商品
    const rt_f_list_category_id = [] // category_id 类目ID
    const rt_f_list_label_id = [] // label_id 标签云的标签id
    const rt_f_search_top_goods = [] // 搜索页首个商品
    let rt_f_search_top_goods_v2 = [] // 搜索商品前20个
    let rt_f_search_unrelated_goods_data = { value: '', timestamp: 0 }

    Object.keys(collectData).forEach(session_id => {
      const {
        click_attribute = [],
        click_sort = [],
        click_category = [],
        click_goods_list_label = [],
        expose_goods_list = [],
        page_view = [],
        customData,
        _routeData = {},
      } = collectData[session_id] || {}

      const _currRoute = _routeData?.to || {}
      // const _preRoute = _routeData?.from
      const _attribute_listFilter = click_attribute?.filter(d => d?.activity_param?.attribute_list)
      const _price_rangeFilter = click_attribute?.filter(d => d?.activity_param?.price_range)

      const _last_attribute_listFilter = _getLastItemByList(_attribute_listFilter)?.activity_param?.attribute_list
      const _last_price_rangeFilter = _getLastItemByList(_price_rangeFilter)?.activity_param?.price_range

      if (_last_attribute_listFilter) {
        rt_ft_list_filter.push(_getSessionItemByList([session_id, _last_attribute_listFilter]))
      }

      if (_last_price_rangeFilter) {
        rt_ft_list_price.push(_getSessionItemByList([session_id, _last_price_rangeFilter]))
      }

      if (click_sort?.length) {
        rt_f_list_sort.push(_getSessionItemByList([session_id, _getLastItemByList(click_sort)?.activity_param?.sort || '0']))
      }

      // 是否搜索结果页
      const _isSearchPage = _currRoute.path?.match(new RegExp('pdsearch/.+'))
      const searchPageView = page_view.filter(d => d?.result_content)

      if (_isSearchPage) {
        const firstExporseGoodsId = expose_goods_list?.[0]?.activity_param?.goods_list?.split('`')?.[0] // 搜索首个曝光的商品goodsid

        if (firstExporseGoodsId) {
          rt_f_search_top_goods.push(_getSessionItemByList([session_id, firstExporseGoodsId]))
        }
      }

      if (_isSearchPage && _getLastItemByList(searchPageView)?.result_content) {
        rt_f_search_identifier.push(_getSessionItemByList([session_id, _getLastItemByList(searchPageView).result_content], '`'))
      }

      // 首页的SBC、首页导航、首页信息流 要上报goodsId
      const _isFromValidHome = _currRoute.query?.categoryJump // 首页导航
      || _currRoute.query?.entranceType === 'sbc' // 来自首页的SBC
  
      if (_isFromValidHome) {
        const firstExporseGoodsId = expose_goods_list?.[0]?.activity_param?.goods_list?.split('`')?.[0] // 首个曝光的商品goodsid
        const timeStamp = expose_goods_list?.[0]?.timestamp // 首个曝光的商品时间戳

        if (firstExporseGoodsId) rt_f_list_top_goods.push(_getSessionItemByList([session_id, firstExporseGoodsId, timeStamp]))
      }
 

      // category_id 类目ID
      if (click_category?.length) {
        rt_f_list_category_id.push(_getSessionItemByList([session_id, _getLastItemByList(click_category)?.activity_param?.category_id]))
      }

      // label_id 标签云的标签id
      if (click_goods_list_label?.length) {
        rt_f_list_label_id.push(_getSessionItemByList([session_id, _getLastItemByList(click_goods_list_label)?.activity_param?.label_id]))
      }

      // 搜索结果页前20个商品
      if (customData?.searchGoods) {
        rt_f_search_top_goods_v2 = rt_f_search_top_goods_v2.concat([{ ...customData.searchGoods, session_id }])
      }

      if (customData?.rt_f_search_unrelated_goods?.value && (customData?.rt_f_search_unrelated_goods?.timestamp > rt_f_search_unrelated_goods_data.timestamp)) {
        rt_f_search_unrelated_goods_data = customData.rt_f_search_unrelated_goods
      }
    })

    // 搜索结果页前20个商品，取最新的20个
    if (rt_f_search_top_goods_v2?.length) {
      rt_f_search_top_goods_v2 = getSearchTop20Goods(rt_f_search_top_goods_v2)
    }

    // 全局收集的行为
    const {
      top40ExposeGoodsList,
      top40ClickGoodsList,
      top40ClickCheckoutList,
      top40ClickAddCollectList,
      top40ClickAddBagList,
    } = getUserActionTracker('commonActions')

    const result = {
      rec_ctx: {
        // 无结果传undefined
        rt_ft_list_filter: _getSessionItemByList(rt_ft_list_filter),
        rt_ft_list_price: _getSessionItemByList(rt_ft_list_price),
        rt_f_list_sort: _getSessionItemByList(rt_f_list_sort),
        // rt_f_list_top_nav: _getSessionItemByList(rt_f_list_top_nav, '`'),
        rt_f_search_identifier: _getSessionItemByList(rt_f_search_identifier, '`'),
        rt_f_list_top_goods: _getSessionItemByList(rt_f_list_top_goods),
        rt_f_list_category_id: _getSessionItemByList(rt_f_list_category_id),
        rt_f_list_label_id: _getSessionItemByList(rt_f_list_label_id),
        rt_f_search_top_goods: _getSessionItemByList(rt_f_search_top_goods),
        rt_f_search_top_goods_v2: _getSessionItemByList(rt_f_search_top_goods_v2, '`'),
        rt_f_search_unrelated_goods: rt_f_search_unrelated_goods_data?.value || '',
        rt_f_user_impr_v2: top40ExposeGoodsList,
        rt_f_user_clk_v3: top40ClickGoodsList,
        rt_f_user_order_v2: top40ClickCheckoutList,
        rt_f_user_fav_v2: top40ClickAddCollectList,
        rt_f_user_cart_v2: top40ClickAddBagList,
      }
    }

    console.warn('detailsRecommend result', result)

    return result
  }
}

export default config
