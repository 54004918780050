import { getSessionStorage, setSessionStorage } from '@shein/common-function'
import { log } from './utils.js'

class TrackingController {
  constructor({ debug }) {
    this.debug = debug || false
    // 收集的实例集合
    this.collectDataInstanceHandlers = []
    // 路由的实例集合
    this.routerActionInstanceHandlers = []
  }

  /**
   * @description: 注册
   * */
  registerHandler({ key, collectDataInstance, routerActionInstance, localInitData }) {
    const collectDataKey = `action_track_${key}_collect`
    const routerActionKey = `action_track_${key}_router`

    const routerActionData = getSessionStorage(routerActionKey)
    const collectData = getSessionStorage(collectDataKey)
    collectData && collectDataInstance.setInitData(collectData)
    routerActionData && routerActionInstance.setInitData(routerActionData)

    if (Array.isArray(localInitData)) {
      if (!collectData) {
        collectDataInstance.startCollect()
      }
      try {
        localInitData.forEach(item => { collectDataInstance.trigger(item) })
      } catch (e) {
        log(`[${key}]初始化数据失败`, e)
      }
    }

    collectDataInstance.registerHandler({
      watch: (data, value) => {
        setSessionStorage({
          key: collectDataKey,
          value: data
        })
        if (this.debug) {
          log(`[${key}]收集数据发生变化`, data, value)
        }
      }
    })
    this.collectDataInstanceHandlers.push(collectDataInstance)
    routerActionInstance.registerHandler({
      onStartCallback: (data) => {
        this.debug && log(`[${key}]收集start`)
        collectDataInstance.startCollect(data)
      },
      onEndCallback: () => {
        this.debug && log(`[${key}]收集数据end，清空数据`)
        collectDataInstance.endCollect()
      },
      targetTriggerCallback: (data) => {
        collectDataInstance.startCollect(data)
      },
      watch: (data) => {
        setSessionStorage({
          key: routerActionKey,
          value: data
        })
        if (this.debug) {
          log(`[${key}]路由行为发生变化`, data)
        }
      }
    })
    this.routerActionInstanceHandlers.push(routerActionInstance)

    if (this.debug) {
      log(`[${key}]注册成功,初始化数据`, { collectData: collectDataInstance.getData(), routerAction: routerActionInstance.getData() })
    }
  }
  
  /**
   * @description: 触发收集数据(埋点每次触发)
  */
  triggerCollectDataHandlers(data) {
    this.collectDataInstanceHandlers.forEach(handler => {
      handler.trigger(data)
    })
  }

  /**
   * @description: 触发路由行为
   * */
  triggerRouterActionHandlers(to, from) {
    this.routerActionInstanceHandlers.forEach(handler => {
      handler.trigger(to, from)
    })
  }
}

export { TrackingController }
